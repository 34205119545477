import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 868.000000 646.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,646.000000) scale(0.100000,-0.100000)">
					<path d="M4156 6039 c-171 -18 -455 -88 -576 -141 -25 -11 -61 -27 -80 -35
-243 -104 -471 -261 -679 -466 -190 -187 -328 -378 -441 -610 -102 -209 -167
-413 -207 -652 -25 -153 -25 -557 1 -695 100 -547 353 -1001 746 -1346 292
-255 684 -447 1060 -518 220 -41 582 -47 790 -11 304 51 633 181 888 350 263
173 524 450 691 730 113 190 215 447 261 655 70 326 68 699 -6 1010 -27 113
-78 276 -100 319 -8 14 -14 30 -14 34 0 10 -104 219 -139 281 -54 94 -180 261
-274 366 -313 348 -743 595 -1207 694 -202 43 -501 57 -714 35z m541 -44 c388
-51 794 -224 1093 -464 134 -108 304 -285 395 -411 21 -30 42 -57 45 -60 31
-27 179 -298 224 -410 56 -137 112 -322 131 -430 37 -209 48 -524 25 -695 -58
-429 -232 -834 -494 -1145 -144 -171 -310 -321 -487 -438 -87 -58 -278 -160
-359 -192 -30 -12 -64 -26 -75 -30 -61 -25 -207 -70 -280 -87 -63 -14 -229
-42 -285 -48 -111 -11 -402 -6 -524 10 -624 82 -1205 448 -1553 977 -79 121
-162 279 -212 406 -35 91 -43 115 -76 232 -93 328 -101 752 -20 1094 126 533
462 1021 910 1323 166 112 211 135 450 238 108 46 303 97 472 124 154 25 454
27 620 6z"/>
					<path d="M4190 5930 c-226 -23 -469 -89 -668 -181 -34 -16 -65 -29 -68 -29 -4
0 -52 -27 -108 -61 -55 -33 -110 -66 -121 -72 -61 -37 -241 -185 -324 -267
-178 -176 -311 -359 -417 -575 -148 -303 -213 -568 -219 -905 -6 -277 22 -467
106 -722 106 -325 274 -596 523 -843 238 -238 516 -409 834 -514 262 -88 471
-117 757 -108 327 11 589 78 880 224 208 104 361 212 527 372 338 324 553 734
635 1211 25 149 25 533 -1 675 -57 314 -183 633 -334 850 -364 520 -853 830
-1467 931 -109 18 -417 26 -535 14z m45 -224 c19 -8 35 -19 35 -24 0 -18 -14
-21 -41 -7 -67 35 -184 6 -232 -56 -51 -69 -55 -145 -9 -218 64 -104 206 -120
305 -33 23 20 32 10 15 -17 -18 -30 -91 -63 -149 -69 -88 -8 -186 55 -219 143
-16 43 -14 114 5 163 19 48 84 108 130 121 47 14 122 12 160 -3z m473 -2 c193
-80 172 -364 -31 -415 -95 -24 -187 15 -244 103 -23 36 -28 54 -28 109 0 56 5
73 30 115 54 89 177 128 273 88z m453 -120 c93 -49 123 -89 127 -175 3 -55 0
-74 -22 -117 -29 -56 -71 -93 -130 -112 -51 -16 -102 -8 -180 28 l-64 30 86
191 c50 110 93 191 101 191 7 0 44 -16 82 -36z m-1489 -54 c110 -31 182 -170
144 -278 -24 -67 -52 -98 -111 -126 -160 -76 -320 29 -317 209 2 135 145 233
284 195z m1944 -227 c-13 -42 -104 -371 -110 -400 -5 -20 -14 -33 -23 -33 -12
0 -14 7 -9 30 19 92 24 79 -69 152 l-85 67 -46 -20 c-25 -12 -53 -18 -62 -14
-18 7 -8 13 198 122 146 78 174 94 185 104 15 15 27 10 21 -8z m-2324 -170
c131 -131 165 -173 141 -173 -4 0 -39 31 -78 70 l-70 70 -90 -90 -90 -90 74
-74 c49 -50 69 -76 61 -81 -14 -8 -310 281 -310 302 0 23 14 14 85 -57 l70
-70 90 90 90 90 -68 68 c-60 61 -81 92 -60 92 4 0 74 -66 155 -147z m2594 -74
c63 -73 83 -104 75 -112 -6 -6 -12 -9 -14 -6 -1 2 -19 22 -38 45 l-36 41 -84
-70 c-46 -38 -115 -97 -152 -130 -46 -40 -72 -56 -78 -50 -7 7 37 51 133 133
79 68 147 126 152 131 5 5 -7 28 -28 54 -31 37 -40 65 -21 65 2 0 43 -45 91
-101z m-1190 33 c463 -108 827 -441 977 -892 71 -215 80 -525 20 -740 -90
-328 -285 -598 -558 -776 -234 -151 -455 -217 -737 -216 -273 0 -514 74 -740
228 -368 251 -579 652 -579 1101 1 362 137 689 393 940 192 189 408 305 678
362 132 28 411 24 546 -7z m-1837 -214 c0 -7 -19 -27 -42 -43 -80 -58 -108
-156 -68 -238 12 -24 29 -51 39 -59 122 -108 316 -29 325 131 3 55 25 86 29
39 4 -70 -14 -128 -55 -174 -125 -143 -353 -75 -383 114 -10 61 18 140 63 183
55 53 94 72 92 47z m3170 -220 c-40 -51 -103 -135 -142 -185 -62 -83 -87 -103
-87 -72 0 6 14 27 31 49 l31 38 -24 38 c-14 22 -27 47 -31 56 -3 9 -18 35 -32
59 l-27 42 -54 -7 c-44 -5 -54 -3 -54 9 0 8 1 15 3 16 16 3 401 47 425 48 l33
1 -72 -92z m-468 -2042 l-20 -21 65 -67 c35 -37 64 -73 64 -80 0 -7 -32 20
-69 61 l-69 74 -26 -17 c-38 -25 -32 -7 16 41 41 42 78 51 39 9z m-2376 -56
c-44 -44 -82 -75 -84 -69 -5 15 129 149 148 149 9 0 -20 -36 -64 -80z m121
-90 l-5 -110 37 43 c20 23 47 56 59 72 12 17 23 24 23 17 0 -7 -29 -49 -65
-93 l-65 -81 0 113 0 113 -37 -44 c-21 -24 -45 -53 -55 -64 -9 -12 -19 -18
-22 -15 -7 7 115 158 127 159 5 0 6 -49 3 -110z m2154 41 c0 -5 -10 -11 -21
-13 -31 -4 -47 -33 -33 -55 12 -19 27 -21 86 -10 60 12 89 -38 52 -91 -11 -16
-24 -22 -52 -21 -35 1 -35 2 -8 8 16 3 38 18 49 33 32 43 11 59 -72 56 l-66
-3 -3 36 c-2 24 3 42 13 53 18 17 55 22 55 7z m-120 -112 c0 -5 14 -30 30 -56
35 -55 38 -85 10 -113 -27 -27 -71 -26 -97 3 -31 33 -85 128 -77 136 4 3 9 1
11 -6 11 -31 83 -131 98 -137 28 -10 53 2 62 31 8 22 3 38 -29 90 -40 66 -49
95 -23 73 8 -7 15 -16 15 -21z m-1672 -59 c17 -11 32 -27 32 -36 0 -13 -6 -11
-26 10 -58 58 -154 20 -154 -61 0 -43 5 -52 40 -76 37 -26 67 -22 84 12 14 27
14 29 -11 40 -36 16 -21 27 17 11 16 -7 30 -14 30 -17 0 -15 -54 -105 -57 -95
-3 6 -11 12 -19 12 -28 0 -74 31 -89 60 -22 43 -19 84 10 119 37 44 92 52 143
21z m1497 -40 c13 -14 16 -28 11 -52 -6 -29 -2 -36 31 -65 48 -41 29 -43 -22
-3 -30 23 -41 27 -56 19 -23 -12 -24 -27 -4 -53 17 -22 20 -46 6 -46 -5 0 -15
15 -21 34 -6 19 -24 60 -41 92 l-29 58 32 18 c43 23 71 22 93 -2z m-160 -40
c-3 -5 -15 -10 -26 -10 -25 0 -24 -13 10 -114 16 -47 26 -86 22 -86 -5 0 -53
119 -67 167 -2 9 -14 11 -36 6 -47 -9 -28 6 34 28 63 21 71 22 63 9z m-1090
-31 c30 -27 35 -37 35 -75 0 -34 -6 -51 -29 -76 -25 -28 -36 -33 -75 -33 -38
0 -51 5 -77 31 -53 53 -39 141 26 170 48 21 81 17 120 -17z m188 -43 c50 -20
67 -43 67 -87 0 -83 -52 -126 -135 -112 -40 6 -39 2 -20 108 8 44 14 86 15 93
0 16 33 15 73 -2z m622 -6 c-11 -5 -32 -9 -47 -9 -30 -1 -33 -12 -19 -61 6
-22 12 -24 51 -23 l45 2 -42 -11 c-45 -11 -47 -16 -37 -65 6 -29 7 -30 42 -22
47 11 62 11 62 0 0 -8 -100 -24 -108 -16 -2 2 -12 49 -21 104 l-18 100 31 4
c17 2 42 4 56 5 23 1 23 0 5 -8z m-284 -57 l23 -47 19 47 c11 26 23 47 28 47
12 0 12 0 -14 -61 -22 -52 -22 -55 -6 -87 l17 -34 19 44 c11 23 28 64 39 90
10 27 23 48 28 48 10 0 8 -6 -41 -122 -21 -49 -40 -88 -43 -88 -4 0 -14 17
-24 37 l-18 38 -22 -43 -22 -43 -44 88 c-57 113 -61 123 -50 123 5 0 28 -39
50 -87 41 -86 42 -87 56 -63 19 35 17 48 -10 102 -20 39 -23 58 -10 58 1 0 12
-21 25 -47z"/>
					<path d="M4576 5689 c-129 -37 -188 -194 -110 -296 91 -119 261 -105 329 27
19 37 23 112 7 151 -34 86 -142 142 -226 118z"/>
					<path d="M5012 5433 c-39 -87 -73 -164 -74 -172 -2 -9 19 -23 57 -38 115 -48
206 -12 253 98 41 100 9 181 -96 237 -33 18 -62 32 -64 32 -3 0 -37 -71 -76
-157z"/>
					<path d="M3545 5503 c-11 -3 -36 -18 -56 -34 -89 -70 -100 -191 -26 -276 39
-44 82 -63 142 -63 60 0 103 19 142 63 63 72 68 178 11 243 -55 62 -133 87
-213 67z"/>
					<path d="M5529 5241 c-19 -10 -54 -30 -79 -45 -25 -15 -60 -33 -78 -42 l-33
-15 81 -65 c45 -36 82 -64 84 -62 8 11 68 239 64 243 -2 2 -20 -4 -39 -14z"/>
					<path d="M4185 5033 c-142 -25 -309 -89 -435 -167 -268 -165 -469 -431 -553
-731 -39 -139 -51 -246 -44 -402 9 -199 42 -324 134 -508 135 -269 383 -496
658 -601 416 -159 882 -94 1220 169 326 254 496 601 496 1011 0 609 -449 1133
-1055 1231 -113 18 -315 18 -421 -2z m815 -260 c31 -17 65 -45 84 -71 l31 -44
0 -376 0 -377 -26 -55 c-31 -64 -96 -132 -154 -159 -22 -10 -67 -31 -100 -46
-159 -73 -252 -116 -267 -125 -31 -17 -103 -12 -130 10 -47 36 -50 57 -46 361
3 311 5 322 69 402 l30 37 -133 0 c-196 0 -287 -29 -397 -127 -69 -61 -126
-149 -152 -236 -17 -56 -20 -86 -17 -170 7 -146 37 -216 138 -318 138 -140
203 -159 567 -159 143 0 274 -5 303 -11 102 -22 186 -89 236 -189 23 -46 29
-72 33 -163 l6 -108 -475 4 c-520 4 -526 4 -690 71 -262 107 -489 364 -563
638 -19 72 -22 107 -21 263 0 212 15 283 89 431 136 272 394 469 680 522 33 7
69 13 80 16 11 2 189 4 396 5 l377 1 52 -27z"/>
					<path d="M4997 4517 c-20 -73 -164 -181 -300 -224 -112 -36 -142 -55 -171
-110 -14 -27 -16 -71 -16 -293 0 -161 4 -260 10 -260 21 0 344 154 393 187 21
15 50 47 65 72 l27 46 3 250 c4 290 1 375 -11 332z"/>
					<path d="M5930 4709 c-36 -5 -84 -12 -107 -15 -24 -3 -43 -8 -43 -12 0 -14 85
-156 96 -159 9 -3 87 89 152 179 15 21 10 21 -98 7z"/>
					<path d="M5098 2298 c-30 -10 -33 -22 -18 -62 11 -30 30 -33 58 -8 46 38 16
90 -40 70z"/>
					<path d="M3778 2210 c-26 -27 -30 -36 -25 -68 12 -75 92 -106 152 -58 25 20
30 30 29 67 -3 85 -95 120 -156 59z"/>
					<path d="M4032 2104 c-17 -108 -18 -104 27 -104 58 0 96 35 96 89 0 50 -26 79
-76 87 l-35 6 -12 -78z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
